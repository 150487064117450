import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../images/logo.png";
import axiosInstance from "./../axiosConfig";

function ForgotPassword() {
  const [action, setAction] = useState("");

  function SelectEmail() {
    const loginRef = useRef();
    const [error, setError] = useState("");

    const onSubmit = async (ev) => {
      ev.preventDefault();

      const payload = {
        login: loginRef.current.value,
      };

      try {
        const response = await axiosInstance.post("/forgot-password", payload);
        console.log(response);
        setAction("changpassword");
      } catch (error) {
        console.log(error.response.data.error);
        setError(error.response.data.error);
      }
    };

    return (
      <>
        <div className="containt-login-logo">
          <img src={logo} alt="Logo de l'eglise" className="login-logo" />
        </div>
        <form onSubmit={onSubmit}>
          <div className="block-label">
            <span className="obligatoire">*</span>
            <label htmlFor="login" className="block-label">
              Login :
            </label>
            <span className="error">{error}</span>
          </div>
          <div className="block">
            <input
              type="email"
              ref={loginRef}
              className="form-control"
              id="login"
              placeholder="Entrez votre email..."
              required
            />
          </div>
          <div className="block-button">
            <button type="submit" className="btn">
              Valider
            </button>
          </div>
          <div className="block-link">
            <div className="forgot-password">
              <Link to="/login"> Retour </Link>
            </div>
          </div>
        </form>
      </>
    );
  }

  function ChangePasseword() {
    return (
      <>
        <div className="text-center mt-60">
          Un lien pour réinitialiser votre mot de passe vous a été envoyé par
          email. Veuillez vérifier votre boîte de réception !
        </div>
      </>
    );
  }

  //gestion pages avec button
  const renderForm = () => {
    switch (action) {
      case "changpassword":
        return <ChangePasseword />;
      case "selectemail":
        return <SelectEmail />;
      default:
        return <SelectEmail />;
    }
  };

  return (
    <>
      <div className="container-xxl">
        <div className="container-login">{renderForm()}</div>
      </div>
    </>
  );
}

export default ForgotPassword;
